import React, { useContext, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  MenuList,
  ThemeProvider,
  Tooltip
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import Logout from '@mui/icons-material/Logout';
import Check from '@mui/icons-material/Check';
import { useAppDispatch } from 'src/redux/hooks';
import { logout } from 'src/redux/slices/auth/authSlice';
import { useAppSelector } from 'src/redux/hooks';
import { setLanguage } from 'src/redux/slices/LanguageSlice';
import { useTranslation } from 'react-i18next';
import { setLanguageLocalStorage } from 'src/services/utils/setLanguageLocalStorage';
import {
  NavbarCardBoxItemsColumn,
  NavbarCardBoxItemsRow,
  NavBarDetails,
  StyledEmail,
  StyledName,
  StyledRol
} from './NavBarStyles/NavbarCard.styles';
import { getUserType } from './utils';
import { ThemeContext } from 'styled-components';
import { setTheme } from 'src/redux/slices/theme.slice';
import { getThemeFromUrl } from 'src/Themes/getThemeFromUrl';

const MenuComponent = () => {
  const { t } = useTranslation();
  const dispatch: any = useAppDispatch();
  const { color } = useContext(ThemeContext);

  const { selectedTheme } = useAppSelector((state: any) => state.theme);
  const selectedLanguage = useAppSelector(
    state => state.language.selectedLanguage
  );
  const { user, isLoggedIn } = useAppSelector(state => state.auth);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const changeThemeColors = () => {
    dispatch(setTheme(selectedTheme === 'dark' ? 'light' : 'dark'));
    localStorage.setItem(
      'dark-mode',
      (selectedTheme === 'dark' ? 'false' : 'true').toString()
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickLogout = async () => {
    handleClose();
    await dispatch(logout());
  };

  const handleLanguageChange = (newLanguage: string) => {
    dispatch(setLanguage(newLanguage));
    setLanguageLocalStorage(newLanguage);
  };

  return (
    <div>
      <Tooltip title={t('Menu')}>
        <IconButton
          style={{ color: color.navBarIcon }}
          aria-label='more'
          id='long-button'
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          data-testid='triple-dot-button'
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            backgroundColor: color.bkg,
            color: color.txt,
            svg: {
              color: color.txt
            },
            overflow: 'visible',
            // maxWidth: 360,
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: color.bkg,
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuList dense sx={{ minWidth: 180 }}>
          {isLoggedIn && (
            <NavbarCardBoxItemsRow>
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  fontSize: 16,
                  bgcolor: color.navBarIcon,
                  color: color.navBarTxt
                }}
              >
                {user.first_name.charAt(0) + user.last_name.charAt(0)}
              </Avatar>

              <NavbarCardBoxItemsColumn>
                <NavBarDetails>
                  <StyledName>
                    {user.first_name}
                    {`${' '}`}
                    {user.last_name}
                  </StyledName>
                  <StyledEmail>{user.email}</StyledEmail>
                </NavBarDetails>
                <StyledRol>{t(getUserType(user.groups))}</StyledRol>
              </NavbarCardBoxItemsColumn>
            </NavbarCardBoxItemsRow>
          )}

          {isLoggedIn && (
            <Divider sx={{ backgroundColor: color.border, opacity: '.6' }} />
          )}
          <MenuItem disabled>
            <ListItemIcon>
              <LanguageIcon />
            </ListItemIcon>
            {t('Language')}
          </MenuItem>

          <MenuItem onClick={() => handleLanguageChange('en')}>
            <ListItemIcon>
              {selectedLanguage === 'en' && <Check />}
            </ListItemIcon>
            {t('English')}
          </MenuItem>

          <MenuItem onClick={() => handleLanguageChange('es')}>
            <ListItemIcon>
              {selectedLanguage === 'es' && <Check />}
            </ListItemIcon>
            {t('Spanish')}
          </MenuItem>

          <MenuItem onClick={() => handleLanguageChange('pt-BR')}>
            <ListItemIcon>
              {selectedLanguage === 'pt-BR' && <Check />}
            </ListItemIcon>
            {t('Portuguese')}
          </MenuItem>

          {/* {
            (getThemeFromUrl()==='inceptia')
            &&
              <>
                <Divider sx={{backgroundColor: color.border, opacity:'.6'}}/>

                <MenuItem onClick={changeThemeColors} disabled>
                  <ListItemIcon>
                    {localStorage.getItem('dark-mode') === 'true' && <Check />}
                  </ListItemIcon>
                  {t('Dark mode')}
                </MenuItem>
              </> 
          } */}

          {isLoggedIn && (
            <Divider sx={{ backgroundColor: color.border, opacity: '.6' }} />
          )}
          {isLoggedIn && (
            <MenuItem onClick={handleClickLogout}>
              <ListItemIcon>
                <Logout fontSize='small' />
              </ListItemIcon>
              {t('Logout')}
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </div>
  );
};

export default MenuComponent;
