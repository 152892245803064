/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, useEffect, useState } from 'react'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { NavBar } from 'src/components/navBar/NavBar';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
// import { getMSAuthUrl, loginMsUser } from 'src/services/loginServices/msAuthApiServices.api';
import { NotFound } from '../components/SpecialPages/notFound';
import PrivateRoutes from './PrivateRouter.router';
import PublicRoutes from './PublicRouter.router';
import Backdrop from '@mui/material/Backdrop';
import { LoaderStyled } from 'src/components/loader/LoaderFallback';
import { useIsUserLoggedIn } from './useIsUserLoggedIn';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import en from '../i18n/en.json';
import es from '../i18n/es.json';
import ptBR from '../i18n/pt-BR.json';
import InternalServerError from 'src/pages/Internal/InternalServerError';
import { getOauthService } from 'src/services/loginServices/getOauthService.api';
import { login } from 'src/redux/slices/auth/authSlice';

const Login = React.memo(lazy(() => import('../pages/Login/Login.router')));
const Dashboard = React.memo(
  lazy(() => import('../pages/Dashboard/Dashboard.router'))
);

export const AppRouter = () => {
  const { user, status } = useAppSelector((state: any) => state.auth);
  const [querys] = useState(window.location.search);
  const { isUserLoggedIn, pathname } = useIsUserLoggedIn();
  const { selectedLanguage } = useAppSelector((state: any) => state.language);
  const dispatch = useAppDispatch();
  const [isI18nInitialized, setIsI18nInitialized] = useState(false);
  const [isMSAuthUrlLoading, setIsMSAuthUrlLoading] = useState(true);

  useEffect(() => {
    i18n
      .use(initReactI18next)
      .init({
        resources: {
          en: {
            translation: en,
          },
          es: {
            translation: es,
          },
          pt: {
            translation: ptBR,
          },
        },
        lng: selectedLanguage,
        fallbackLng: 'en',
        interpolation: {
          escapeValue: false,
        },
      })
      .then(() => {
        setIsI18nInitialized(true);
      });
  }, [selectedLanguage]);

  useEffect(() => {
    const initializeExtraAuthUrl = async () => {
      try {
        if (isUserLoggedIn === false && Object.keys(user).length === 0) {
          if (window.location.href.includes('success_login=True')) {
            await dispatch(login({ useTokenFromLocalStorage: true }));
          } else {
            await getOauthService();
          }
        }
      } catch (error) {
        console.error('Error fetching MS Auth URL:', error);
      } finally {
        setIsMSAuthUrlLoading(false);
      }
    };
    initializeExtraAuthUrl();
  }, []);

  return (
    <BrowserRouter>
      <Backdrop
        open={status === 'loading' && isUserLoggedIn}
        sx={{
          color: '#fff',
          backgroundColor: '#000000c2',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <LoaderStyled />
      </Backdrop>

      {isI18nInitialized && !isMSAuthUrlLoading ? (
        <>
          <NavBar />
          <Routes>
            <Route path='*' element={<NotFound />} />
            <Route
              path='/internalServerError'
              element={<InternalServerError />}
            />
            <Route
              element={
                <PublicRoutes
                  isUserLoggenIn={isUserLoggedIn}
                  pathname={pathname}
                  querys={querys}
                />
              }
            >
              <Route path='/' element={<Navigate to='login' />} />
              <Route path={'login/*'} element={<Login />} />
            </Route>
            <Route
              element={
                <PrivateRoutes user={user} isUserLoggenIn={isUserLoggedIn} />
              }
            >
              <Route path={'dashboard/*'} element={<Dashboard />} />
            </Route>
          </Routes>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            backgroundColor: '#f5f5f5',
          }}
        ></div>
      )}
    </BrowserRouter>
  );
};

export { i18n };