import axios from "axios";
import { BM_API_URL } from "../utils/api";

const get_login_url = `${BM_API_URL}/auth/oauth/`;

export const getOauthService = async () => {
  try {
    const response = await axios.get(
      get_login_url,
      { withCredentials: true });
    localStorage.setItem("oauth", JSON.stringify(response.data));
  } catch (error) {
    console.log(error);

  }
};