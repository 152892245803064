import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { loginApiService, loginApiService2FA } from "src/services/loginServices/loginApiService.api";
import { logoutApiService } from "src/services/loginServices/logoutApiService.api";
import { getActiveUser } from "src/services/utils/getActiveUser";
import { getUserService } from "src/services/loginServices/getUserService.api";
import { parserByBgName } from "src/utilities/parserByBgName";
import { loginWithMsftApiService } from "src/services/loginServices/loginWithMsftApiService.api";
import { getOauthService } from "src/services/loginServices/getOauthService.api";

interface LoginValues {
  username: string;
  password: string;
};

interface PropsLogin {
  values?: LoginValues;
  is2FA?: boolean;
  useTokenFromLocalStorage?: boolean;
};


export const login = createAsyncThunk(
  'auth/login',
  async ({ values = { username: '', password: '' }, is2FA = false, useTokenFromLocalStorage = false }: PropsLogin, { rejectWithValue }) => {
    try {
      let response: any = null;
      if (is2FA) {
        response = await loginApiService2FA(values);
      } else if (useTokenFromLocalStorage) {
        response = await loginWithMsftApiService();
      } else {
        response = await loginApiService(values); 
      }

      // OFA
      if (response.status === 200) {
        try {
          const responseUser: any = await getUserService(response.data.access);
          const businessGroupNames = responseUser.data.business_groups.map((elem: any) => ({
            id: elem.id,
            name: parserByBgName(elem.name),
          }));
          responseUser.data["business_groups"] = businessGroupNames;
          responseUser.data["access"] = response.data.access;
          responseUser.data["refresh"] = response.data.refresh;
          localStorage.setItem("activeUser", JSON.stringify(responseUser.data));
          localStorage.removeItem('tempAccessToken');
          return responseUser;
        } catch (error: any) {
          throw rejectWithValue(error.response.status);
        }
      };

      // This is for 2FA if the response is 202
      if (response.status === 202) {
        response.data["email"] = values.username;
        localStorage.setItem("tempAccessToken", JSON.stringify(response.data));
        return response;
      }
    } catch (error: any) {
      if (useTokenFromLocalStorage) {
        getOauthService();
      }
      throw rejectWithValue(error.response);
    }
  });


export const logout = createAsyncThunk(
  'auth/logout',
  async () => {
    try {
      const response = await logoutApiService();
      return response;
    } catch (error) {
      console.log(error);
    } finally {
      localStorage.removeItem('activeUser');
    }
  });


export const resetStatus = createAction('auth/resetStatus');
export const resetAuth = createAction('auth/resetAuth');

interface Login {
  user: any;
  isLoggedIn: boolean;
  status: any;
  error: any;
};

const initialState: Login =
  Object.entries(getActiveUser()).length === 0
    ? {
      user: {},
      isLoggedIn: false,
      status: '',
      error: null,

    }
    : {
      user: getActiveUser(),
      isLoggedIn: true,
      status: 200,
      error: null,
    };

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(login.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.user = payload?.data;
      state.status = payload?.status;
      state.isLoggedIn = payload?.status === 200 ? true : false;
      state.error = null;
    });
    builder.addCase(login.rejected, (state, { payload }: any) => {
      state.isLoggedIn = false;
      state.status = payload?.status;
      state.error = payload?.data;
      state.user = {};
    });

    builder.addCase(logout.fulfilled, (state) => {
      state.user = {};
      state.status = '';
      state.isLoggedIn = false;
      state.error = null;
    });
    builder.addCase(logout.rejected, (state) => {
      state.user = {};
      state.status = '';
      state.isLoggedIn = false;
      state.error = null;
    });
    builder.addCase(logout.pending, (state) => {
      state.status = 'loading';
    });

    builder.addCase(resetStatus, (state) => {
      state.status = initialState.status;
    });
    builder.addCase(resetAuth, (state) => {
      state.status = initialState.status;
      state.error = initialState.error;
    });

  },

});

export default authSlice.reducer;
